// Libraries
import React from 'react'
import styled from 'styled-components'

// Components
import { grayscale } from 'styles/colors'
// @ts-ignore
import SvgLogo from 'assets/rulo.svg'

const LogoWrapper = styled.svg.attrs(({ color, size, padding }) => ({
  color,
  size,
  padding
}))`
  fill: ${({ color }) => color};
  width: ${({ size }) => size};
  padding: ${({ padding }) => padding};
`

/**
 * @typedef {{
 * color?: string,
 * size?: string,
 * padding?: string
 * }} LogoProps
 * @param {LogoProps} props
 */
const Logo = ({
  color = grayscale.gray2,
  size = '2.5em',
  padding = '1.2em'
}) => {
  return (
    <LogoWrapper color={color} size={size} padding={padding}>
      <SvgLogo title='gadgex-logo' />
    </LogoWrapper>
  )
}

export default Logo
