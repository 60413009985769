import cogoToast from 'cogo-toast'
import store from 'store'
import { mdiRuler, mdiFilter } from '@mdi/js'
/**
 * @param {object} params
 * @returns {string}
 */
export const queryString = params => {
  const query = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
  return `${query.length ? '?' : ''}${query}`
}

export const decodeToken = token => {
  const data = token.split('.')[0]

  const decoded = decodeURIComponent(
    Array.prototype.map
      .call(
        window.atob(data.replace(/_/g, '/').replace(/-/g, '+')),
        c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join('')
  )

  return JSON.parse(decoded)
}

export const handleLogout = () => {
  cogoToast.loading('Vuelve a iniciar sesión').then(() => {
    clearSession()
  })
}

export const clearSession = () => {
  store.clearAll()
  location.reload()
}

export const isRulexSelected = () => {
  const selectedApp = store.get('selected_app') || 'rulex'
  return selectedApp === 'rulex'
}

export const changeApp = (newApp, history) => {
  if (newApp === 'select') {
    history.push('/selecciona-app')
    return
  }
  store.set('selected_app', newApp)
  if (newApp === 'rulex') {
    history.push('/asignacion/reglas')
  } else {
    history.push('/filtex/filtros')
  }
  return
}

export const getUserAppsActivated = () => {
  const {
    extras: { rulex, filtex }
  } = store.get('user_metadata')

  return { rulex, filtex }
}

export const getMessagesApp = (rulexSelected) => {
  if(rulexSelected) {
    return {
      messageChangeApp: 'Ir a Filtex',
      iconApp: mdiFilter,
      newApp: 'filtex',
      currentApp: 'RULEX'
    }
  }

  return {
    messageChangeApp: 'Ir a Rulex',
    iconApp: mdiRuler,
    newApp: 'rulex',
    currentApp: 'FILTEX'
  }
}
