// Libraries
import React from 'react'
import { mdiCheckBold, mdiInformation, mdiDelete, mdiClose } from '@mdi/js'

// Components
import AppIcon from 'components/appIcon'
import {
  ToastContainer,
  Caption,
  CloseButton,
  InfoWrapper
} from './toastStyles'

const toastIcons = [
  {
    type: 'success',
    icon: mdiCheckBold
  },
  {
    type: 'error',
    icon: mdiDelete
  },
  {
    type: 'info',
    icon: mdiInformation
  }
]

const closeToast = () => {
  const toastAlert = document.getElementsByClassName('ct-toast')
  toastAlert[0].classList.add('fade-out')
  setTimeout(() => {
    toastAlert[0].parentNode.removeChild(toastAlert[0])
  }, 200)
}

const Toast = ({ children, type }) => {
  const { icon } = toastIcons.find(({ type: iconType }) => iconType === type)
  return (
    <ToastContainer>
      <InfoWrapper>
        <AppIcon color='white' size='1.8em' path={icon} />
        <Caption>{children}</Caption>
      </InfoWrapper>
      <CloseButton onClick={closeToast}>
        <AppIcon color='white' size='1.8em' path={mdiClose} />
      </CloseButton>
    </ToastContainer>
  )
}

export default Toast
