export const white = '#FFFFFF'
export const black = '#000000'

export const primary = {
  blue1: '#13396A',
  blue2: '#4482D0',
  blue3: '#145DA5',
  blue4: '#C4E0F7',
  blue5: '#CFD8FF',
  blue6: '#E0F1FF',
  blue7: '#DFE5FF',
  blue8: '#2D3662',
  blue9: '#092548'
}

export const secondary = {
  aqua1: '#13396A',
  aqua2: '#37C2CF',
  aqua3: '#8DF0F7',
  aqua4: '#CEFCFF',
  aqua5: '#E5FDFF',
  aqua6: '#F2FEFF'
}

export const tertiary = {
  purple1: '#434476',
  purple2: '#7060B2',
  purple3: '#9D90D1',
  purple4: '#D2C8E6',
  purple5: '#ECE6F6',
  purple6: '#F2F9FF'
}

export const grayscale = {
  gray1: '#404063',
  gray2: '#7F7F9D',
  gray3: '#D0D0E6',
  gray4: '#EDEDF8',
  gray5: '#F2F9FF',
  gray6: '#FFFFFF',
  gray7: '#5F5F79'
}

export const alert = {
  blue1: '#384FA4',
  red1: '#FA648D',
  red2: '#FF8E91',
  red3: '#FFECF3',
  red4: '#FFEEF0',
  yellow1: '#F6D44B',
  yellow2: '#FFE67F',
  yellow3: '#FFFCEE',
  orange1: '#F6A442',
  orange2: '#FFF7EE',
  orange3: '#FFF4E7',
  green1: '#FFE67F',
  green2: '#7FEFC3',
  green3: '#E9FFF8',
  green4: '#38CF94'
}
