import {
  mdiClipboardText,
  mdiFormatListBulleted,
  mdiSwapHorizontal,
  mdiClipboardAccount,
  mdiClipboardCheck,
  mdiCloud,
  mdiHistory,
  mdiFilter
} from '@mdi/js'

export const RULE_TYPES = {
  asignacion: 'assignment',
  reasignacion: 'reassignable',
  virtual: 'virtual'
}

export const RULE_STATUS = [
  { key: 'true', label: 'Activas' },
  { key: 'false', label: 'Inactivas' }
]

export const RULE_STATUS_EXTENDED = [
  ...RULE_STATUS,
  { key: '', label: 'Todas' }
]

export const SORTABLE_PARAMETER_PROPERTIES = [
  { key: 'name', label: 'Nombre' },
  { key: 'datatype', label: 'Tipo' }
]

export const SORTABLE_RULE_PROPERTIES = [
  { key: 'priority', label: 'Prioridad' },
  { key: 'name', label: 'Nombre' }
]

export const PARAMETER_DATA_TYPES = [
  { key: 'string', label: 'alfanumérico' },
  { key: 'number', label: 'numérico' },
  { key: 'boolean', label: 'booleano' }
]

export const PARAMETER_DATA_TYPES_EXTENDED = [
  ...PARAMETER_DATA_TYPES,
  { key: '', label: 'Todos' }
]

export const RULE_GROUPER_OPTIONS = [
  { value: '&&', label: 'y', key: 'and' },
  { value: '||', label: 'o', key: 'or' }
]

export const RELATIONAL_OPERATORS_NUMBER = [
  { value: '<', label: 'Menor que' },
  { value: '>', label: 'Mayor que' },
  { value: '<=', label: 'Menor o igual a' },
  { value: '>=', label: 'Mayor o igual a' },
  { value: '==', label: 'Igual a' },
  { value: '!=', label: 'Diferente de' }
]

export const LEAD_STAGES_OPTIONS = [
  { value: '"No perfila"', label: 'No perfila' },
  { value: '"Cerrado Ganado"', label: 'Cerrado Ganado' },
  { value: '"Contrato"', label: 'Contrato' },
  { value: '"ICSE"', label: 'ICSE' },
  { value: '"Perfila"', label: 'Perfila' },
  { value: '"Propuesta"', label: 'Propuesta' },
  { value: '"Servicio al cliente"', label: 'Servicio al cliente' },
  { value: '"En espera"', label: 'En espera' },
  { value: '"Sin tocar"', label: 'Sin tocar' },
  { value: '"Otra línea de negocio"', label: 'Otra línea de negocio' },
  { value: '"Contrato firmado"', label: 'Contrato firmado' },
  { value: '"Baja mismo mes"', label: 'Baja mismo mes' }
]

export const RELATIONAL_OPERATORS_TEXT = [
  { value: '==', label: 'Igual a' },
  { value: '!=', label: 'Diferente de' }
]

export const NAV_ITEMS = [
  {
    label: 'Asignación',
    route: '/asignacion',
    id: 'assignment-path',
    icon: mdiClipboardAccount
  },
  {
    label: 'Disponibilidad',
    route: '/disponibilidad',
    id: 'availability-path',
    icon: mdiClipboardCheck
  }
]

export const ASSIGNMENT_NAV_ITEMS = [
  {
    label: 'Reglas',
    route: '/asignacion/reglas',
    id: 'rules-assignment-path',
    icon: mdiClipboardText,
    restricted: false
  },
  {
    label: 'Panel',
    route: '/asignacion/panel',
    id: 'assignment-panel-path',
    icon: mdiSwapHorizontal,
    restricted: false
  },
  {
    label: 'Parámetros',
    route: '/asignacion/parametros',
    id: 'assignment-parameters-path',
    icon: mdiFormatListBulleted,
    restricted: true
  },
  {
    label: 'Historial',
    route: '/asignacion/historial',
    id: 'assignment-history-path',
    icon: mdiHistory,
    restricted: false
  }
]

export const AVAILABILITY_NAV_ITEMS = [
  {
    label: 'Reasignar',
    route: '/disponibilidad/reasignacion',
    id: 'availability-reassignment-path',
    icon: mdiClipboardText,
    restricted: false
  },
  {
    label: 'Virtual',
    route: '/disponibilidad/virtual',
    id: 'availability-virtual-path',
    icon: mdiCloud,
    restricted: false
  },
  {
    label: 'Historial',
    route: '/disponibilidad/historial',
    id: 'availability-history-path',
    icon: mdiHistory,
    restricted: false
  }
]

export const COUNTRY_CODES = {
  mexico: 'mx',
  colombia: 'co',
  spain: 'es',
  portugal: 'pt',
  italia: 'it',
  brasil: 'br'
}

export const COGOTOAST_OPTIONS = {
  hideAfter: 3,
  bar: {
    size: '0px'
  },
  renderIcon: () => null
}

export const SUBSTAGE_LABELS = [
  { key: 'rejected_by_client', label: 'Rechazado por cliente' },
  { key: 'rejected_by_edc', label: 'Rechazado por EDC' },
  { key: 'review_pending', label: 'Pediente de revisión' },
  { key: 'sent', label: 'Enviado' },
  { key: 'canceled_after_approve', label: 'Cancelado' },
  { key: 'signed', label: 'Firmado' }
]

export const ACTIVITY_CODE_LABELS = {
  created_rule: 'Creó una regla',
  changed_rule: 'Actualizó una regla',
  deleted_rule: 'Eliminó una regla',
  assigned_team: 'Asignó a un equipo',
  unassigned_team: 'Desasignó a un equipo'
}

export const RULE_TYPES_LABEL = {
  assignment: 'Asignación',
  reassignable: 'Reasignación',
  virtual: 'Virtual'
}

export const RULE_TYPES_AVAILABILITY = [
  { key: 'reassignable', label: 'Reasignación' },
  { key: 'virtual', label: 'Virtual' },
  { key: '', label: 'Todas' }
]

export const DETAIL_LABEL_LOGS = {
  active: 'Estatus',
  priority: 'Prioridad',
  expression: 'Condición',
  team: 'Equipo',
  stage: 'Etapa',
  substage: 'Subetapa'
}

export const ACTIVITY_CODE_LABELS_DETAILS = {
  created_rule: 'Fue creada por:',
  changed_rule: 'Fue actualizada por:',
  deleted_rule: 'Fue eliminada por:',
  assigned_team: 'Fue asignada al equipo por:',
  unassigned_team: 'Fue desasignada del equipo por:'
}

export const ACTIVITY_CODE_MESSAGE_DETAILS = {
  created_rule: 'La regla fue creada con la configuración:',
  changed_rule: 'La regla tiene ahora la configuración:',
  deleted_rule: 'La regla eliminada tenía la configuración:',
  assigned_team: 'La regla fue asignada al:',
  unassigned_team: 'La regla fue desasignada del:'
}

export const FILTEX_DATA_TYPES = [
  { key: 'text', label: 'alfanumérico' },
  { key: 'float', label: 'numérico' },
  { key: 'bool', label: 'booleano' },
  { key: 'date', label: 'fecha' }
]

export const FILTEX_DATA_TYPES_EXTENDED = [
  ...FILTEX_DATA_TYPES,
  { key: '', label: 'Todos' }
]

export const SORTABLE_FILTEX_FIELD_PROPERTIES = [
  { key: 'name', label: 'Nombre' },
  { key: 'data_type', label: 'Tipo' }
]

export const FILTEX_NAV_ITEMS = [
  {
    label: 'Campos',
    route: '/filtex/campos',
    id: 'filtex-fields-path',
    icon: mdiClipboardText,
    restricted: true
  },
  {
    label: 'Filtros',
    route: '/filtex/filtros',
    id: 'filtex-filters-path',
    icon: mdiFilter,
    restricted: false
  },
  {
    label: 'Historial',
    route: '/filtex/historial',
    id: 'filtex-history-path',
    icon: mdiHistory,
    restricted: false
  }
]

export const RANGE_ELASTIC_OPERATORS = ['>', '<', '<=', '>=', '<>']

export const RELATIONAL_OPERATORS_DATE = [
  { value: '<', label: 'Menor a' },
  { value: '>', label: 'Mayor a' },
  { value: '<=', label: 'Menor o igual a' },
  { value: '>=', label: 'Mayor o igual a' },
  { value: '==', label: 'Igual a' },
  { value: '<>', label: 'Últimos X días' }
]

export const OPTIONS_FILTEX_CATEGORIES = [
  { value: 'country', label: 'País' },
  { value: 'manager', label: 'Manager' },
  { value: 'team', label: 'Equipo' },
  { value: 'ard', label: 'Agentes' }
]

export const COUNTRY_OPTIONS = [
  { value: '0', label: 'México', code: 'mx' },
  { value: '1', label: 'Colombia', code: 'co' },
  { value: '2', label: 'España', code: 'es' },
  { value: '3', label: 'Portugal', code: 'pt' },
  { value: '4', label: 'Italia', code: 'it' },
  { value: '5', label: 'Brasil', code: 'br' }
]

export const GROUP_SEPARATOR = /\([^)(]+\)/g
export const OPERATOR_SEPARATOR = /&&|\|\|/g
export const CONDITION_SEPARATOR = /(==|<>|!=|>=|<=|<|>)/g
export const REMOVE_DOUBLE_QUOTE = /^"(.+(?="$))"$/

export const LABELS_FILTEX_CATEGORIES = {
  countries: 'País(es)',
  managers: 'Gerente(s)',
  teams: 'Equipo(s)',
  agents: 'Agente(s)'
}

export const ACTIVITY_CODE_FILTEX_LABELS = {
  created_filter: 'Creó el filtro',
  changed_filter: 'Actualizó el filtro',
  deleted_filter: 'Eliminó el filtro',
  toggled_filter: 'Activó/desactivó el filtro'
}

export const ACTIVITY_CODE_FILTEX_LABELS_DETAILS = {
  created_filter: 'Fue creado por:',
  changed_filter: 'Fue actualizado por:',
  deleted_filter: 'Fue eliminado por:',
  toggled_filter: 'Fue activado/desactivado por:'
}

export const ACTIVITY_CODE_FILTEX_MESSAGE_DETAILS = {
  created_filter: 'El filtro fue creado con la configuración:',
  changed_filter: 'El filtro tiene ahora la configuración:',
  deleted_filter: 'El filtro eliminado tenía la configuración:',
  toggled_filter: 'EL filtro cambio a status:'
}

export const DETAIL_LABEL_FILTEX_LOGS = {
  active: 'Estatus',
  expression: 'Condición',
  name: 'Nombre',
  category: 'Categoría'
}

export const BOOLEAN_OPTIONS = [
  { value: 'true', label: 'Verdadero' },
  { value: 'false', label: 'Falso' }
]

export const RELATIONAL_OPERATORS_BOOLEAN = [
  { value: '==', label: 'Igual a' }
]

export const LEAD_SUB_STAGES_OPTIONS = [
  { value: 'Busca crédito', label: 'Busca crédito' },
  { value: 'Datos incorrectos/falsos', label: 'Datos incorrectos/falsos' },
  { value: 'Entidades donde no negociamos', label: 'Entidades donde no negociamos' },
  { value: 'Deuda Menor', label: 'Deuda Menor' },
  { value: 'Duplicada', label: 'Duplicada' },
  { value: 'Nómina en el mismo banco', label: 'Nómina en el mismo banco' },
  { value: 'Al corriente con sus deudas', label: 'Al corriente con sus deudas' },
  { value: 'Falta monto', label: 'Falta monto' },
  { value: 'Sin capacidad de ahorro', label: 'Sin capacidad de ahorro' },
  { value: 'review_pending', label: 'Pediente de revisión' },
  { value: 'rejected_by_client', label: 'Rechazado por cliente' },
  { value: 'rejected_by_edc', label: 'Rechazado por EDC' },
  { value: 'sent', label: 'Enviado' },
  { value: 'canceled_after_approve', label: 'Cancelado' },
  { value: 'Migrado salesforce', label: 'Migrado salesforce' },
  { value: 'Propuesta rechazada', label: 'Propuesta rechazada' },
  { value: 'Propuesta ganadora', label: 'Propuesta ganadora' },
  { value: 'Propuesta generada', label: 'Propuesta generada' },
  { value: 'La tasa', label: 'La tasa' },
  { value: 'Reactivaciones', label: 'Reactivaciones' },
  { value: 'Llamar más tarde', label: 'Llamar más tarde' },
  { value: 'Posible fraude', label: 'Posible fraude' },
  { value: 'Sin depósito', label: 'Sin depósito' },
  { value: 'Información crediticia', label: 'Información crediticia' },
  { value: 'Sin intención de pago', label: 'Sin intención de pago' },
  { value: 'No le interesa', label: 'No le interesa' },
  { value: 'No perfila', label: 'No perfila' },
  { value: 'No localizado', label: 'No localizado' },
  { value: 'Dar seguimiento', label: 'Dar seguimiento' },
  { value: 'Mucho tiempo', label: 'Mucho tiempo' },
  { value: 'Mejora de descuento', label: 'Mejora de descuento' },
  { value: 'Propuesta', label: 'Propuesta' },
  { value: 'Solicitud de contrato', label: 'Solicitud de contrato' },
  { value: 'Documentos', label: 'Documentos' },
  { value: 'Cita en sucursal', label: 'Cita en sucursal' },
  { value: 'on_hold_by_saving_provider', label: 'En espera por vehículo de ahorro' },
]

export const REGEX_VALID_VALUES = /(^$|==|!=|>|>=|<=|<|\(|\)|&&|\|\|)/g
