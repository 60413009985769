// Libraries
import styled from 'styled-components'

export const ToastContainer = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-between;
`

export const Caption = styled.div`
  color: white;
  font-size: 16px;
  margin-left: 12px;
  cursor: default;
`

export const CloseButton = styled.div`
  display: flex;
  cursor: pointer;
`

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`
