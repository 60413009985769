// Libraries
import styled, { createGlobalStyle } from 'styled-components'
import { primary } from './colors'
// Components
import { alert, secondary } from 'styles/colors'

const size = {
  mobile: '576px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1680px'
}

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`
}

export const AppGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 3rem 1fr;
  grid-template-columns: 6rem 1fr;
  grid-template-areas:
    'topbar topbar'
    'sidebar appbody';
  @media ${device.laptop} {
    grid-template-rows: 5rem 1fr;
  }
`

export const AppWrapper = styled.div`
  height: 100%;
  grid-area: appbody;
  margin-left: -1rem;
  background-color: ${primary.blue1};
`

export const ScrollStyle = `
  overflow-y: scroll;
  scrollbar-color: ${primary.blue9} transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${primary.blue9};
  }
`

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
    line-height: 1;
    height: 100%;
  }
  body #app{
    width: 100%;
    max-width: 1600px;
    margin: 0px auto;
  }

  ol, ul {
	  list-style: none;
  }
  button,
  input,
  select,
  textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
  }
  a {
    text-decoration: none;
  }
  #app {
    height: 100%;
  }
  .ct-toast-success {
    background-color: ${alert.green4} !important;
  }
  .ct-toast-error {
    background-color: ${alert.red2} !important;
  }
  .ct-toast-info {
    background-color: ${secondary.aqua1} !important;
  }
  .ct-text-group {
    margin-left: 0px !important;
  }
  .fade-out {
    animation: fade-out 0.2s;
  }
  @keyframes fade-out {
    from {opacity: 1;}
    to {opacity: 0;}
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`
